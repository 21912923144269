import React from "react";
import { Link as LinkS } from "react-router-dom";
import styled from "styled-components";

const LinkStyle = styled(LinkS)`
  text-decoration: none;
  margin-top: 20px;
`;

const Error = () => {
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h2>404 Error</h2>
      <h3 style={{ marginTop: "20px" }}>Page not found</h3>
      <LinkStyle to="/">Back to home</LinkStyle>
    </div>
  );
};

export default Error;
