import axios from "axios";
import React, { useState, useEffect } from "react";
import { DeleteBtn, ViewDataContainer } from "../ViewData/ViewDataElements";
import {
  makeStyles,
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
} from "@material-ui/core";
import { admin } from "../../Auth/AuthContext";

const UserDetails = () => {
  // <---------------------------- MUI Table Styles ------------------------------->

  const useStyles = makeStyles({
    tableContainer: {
      borderRadius: 15,
      margin: "10px 10px",
      maxWidth: "95%",
      height: "85vh",
      backgroundColor: "var(--white)",
    },
    tableHeaderCell: {
      fontWeight: "bold",
      backgroundColor: "var(--black)",
      color: "var(--white)",
      padding: "auto",
      position: "sticky",
      top: "0",
    },
    tableRow: {
      width: "auto",
      "&:hover": {
        backgroundColor: "var(--light-gray)",
      },
    },
  });
  const classes = useStyles();

  // <--------------------------- Fetching user details to the Table ---------------------->

  const [user, setUser] = useState([]);
  const getUsers = async () => {
    try {
      axios.get("/api/view-users").then((res) => {
        if (res.status === 200) {
          setUser(res.data.user);
          console.log(res);
        }
      });
    } catch {}
  };

  useEffect(() => {
    getUsers();
  }, []);

  // <---------------------------- Delete User from the Table --------------------->

  const deleteUser = (e, id) => {
    e.preventDefault();

    if (!window.confirm("Are You Sure To Delete This User?")) {
      return;
    }
    const thisCliked = e.currentTarget;
    thisCliked.innerText = "Deleting";

    axios.delete(`api/delete-users/${id}`).then((res) => {
      if (res.data.status === 200) {
        alert(res.data.message);
        thisCliked.closest("tr").remove();
      } else if (res.data.status === 404) {
        alert(res.data.message);
        thisCliked.innerText = "Delete";
      }
    });
  };

  // <---------------------------- Pagination to the Table ------------------------>

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <ViewDataContainer>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table
          aria-label="simple table"
          fixedHeader={false}
          style={{ tableLayout: "auto" }}
        >
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeaderCell} align="left">
                First&nbsp;Name
              </TableCell>
              <TableCell className={classes.tableHeaderCell} align="left">
                Last&nbsp;Name
              </TableCell>
              <TableCell className={classes.tableHeaderCell} align="left">
                Email&nbsp;Address
              </TableCell>
              <TableCell className={classes.tableHeaderCell} align="center">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {user.map((item) => (
              <TableRow key={item.id} className={classes.tableRow}>
                <TableCell align="left">{item.first_name}</TableCell>
                <TableCell align="left">{item.last_name}</TableCell>
                <TableCell align="left">{item.email}</TableCell>
                <TableCell align="center">
                  {item.email === admin ? (
                    <b>Admin</b>
                  ) : (
                    <DeleteBtn onClick={(e) => deleteUser(e, item.id)}>
                      Delete
                    </DeleteBtn>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10]}
        component="div"
        count={user.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </ViewDataContainer>
  );
};

export default UserDetails;
