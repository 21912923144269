import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import MobileMenu from "./Components/Mobile/MobileMenu";
import Sidebar from "./Components/Sidebar/Sidebar";

const Div = styled.div`
  display: flex;
  height: auto;
  overflow: hidden;
  overflow-y: scroll;
`;

const SharedLayout = () => {
  const [isOpened, setIsOpened] = useState(false);

  const toggled = () => {
    setIsOpened(!isOpened);
  };
  return (
    <Div>
      <MobileMenu isOpened={isOpened} toggled={toggled} />
      <Sidebar toggled={toggled} />
      <Outlet />
    </Div>
  );
};

export default SharedLayout;
