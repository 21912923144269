import styled from "styled-components";

export const GalleryContainer = styled.div`
  height: 800px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: #010606;

  @media screen and (max-width: 1000px) {
    height: 1400px;
  }

  @media screen and (max-width: 768px) {
    height: 1400px;
  }
  @media screen and (max-width: 480px) {
    height: 1900px;
  }
`;

export const GalleryWrapper = styled.div`
  margin: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  padding: 0 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

export const GalleryCard = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  max-height: 175px;
  padding: 10px;
  box-shadow: 0 1px 3px rbga(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: scale(1.02);
    transition: all 0.2s ease-in-out;
  }
`;

export const GalleryIcon = styled.img`
  height: 160px;
  width: 200px;
  object-fit: cover;
  -o-object-fit: cover;
`;

export const GalleryH1 = styled.h1`
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 40px;

  @media screen and (max-width: 1080px) {
    margin-top: 120px;
  }

  @media screen and (max-width: 1000px) {
    margin-top: 30px;
  }

  @media screen and (max-width: 800px) {
    margin-top: 610px;
  }

  @media screen and (max-width: 480px) {
    font-size: 2rem;
    margin-top: 50px;
  }
`;
