import React from "react";
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesH2,
  ServicesIcon,
  ServicesP,
} from "./ServiceElements";
import Icon1 from "../../images/subagency.jpg"
import Icon2 from "../../images/supply.jpg"
import Icon3 from "../../images/manpower.jpeg"
import Icon4 from "../../images/shipchandler.jpeg"

const Services = () => {
  return (
    <ServicesContainer id="services">
      <ServicesH1>Our Best Services</ServicesH1>
      <ServicesWrapper>

        <ServicesCard>
          <ServicesIcon 
          src={Icon1} 
          />
          <ServicesH2>Shipping Agent- Sub Agencies Work</ServicesH2>
        </ServicesCard>

        <ServicesCard>
          <ServicesIcon 
          src={Icon2} 
          />
          <ServicesH2>Supply Boat Services</ServicesH2>
          <ServicesP>Provision Supply, Ship Stores Supply, Crew Change & etc</ServicesP>
        </ServicesCard>

        <ServicesCard>
          <ServicesIcon 
          src={Icon3} 
          />
          <ServicesH2>Manpower Supply</ServicesH2>
          <ServicesP>Providing Human Capital</ServicesP>
        </ServicesCard>

        <ServicesCard>
          <ServicesIcon 
          src={Icon4} 
          />
          <ServicesH2>Ship Chandlers</ServicesH2>
        </ServicesCard>

      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;