import styled from "styled-components";
import { Link as LinkS } from "react-scroll";
import { Link as LinkR } from "react-router-dom";

export const Nav = styled.nav`
  /* background: ${({ scrollNav }) => (scrollNav ? "#000" : "#00000095")}; */
  background: #000;
  height: 70px;
  margin-top: -70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  position: sticky;
  top: 0;
  z-index: 30;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

export const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  max-width: 1200px;
  padding: 0 0px;
  margin-right: 20px;
`;

export const NavLogo = styled.div`
  background-color: #fff;
  justify-self: flex-start;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 200px;
  padding: 0.5rem;
  margin-bottom: -3rem;
  margin-top: 1rem;
  height: 100px;
  width: 100px;
  box-shadow: 2px 1px 20px rgba(0, 0, 0, 0.2);
  @media screen and (max-width: 1268px) {
    margin-left: 25px;
  }
  @media screen and (max-width: 768px) {
    margin-left: 2rem;
    height: 90px;
    width: 90px;
  }
`;

export const NavImg = styled.img`
  height: 60px;
  width: 100px;
  object-fit: cover;
  @media screen and (max-width: 768px) {
    height: 50px;
    width: 80px;
  }
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 800px) {
    display: block;
    position: absolute;
    top: 25px;
    right: 20px;
    transform: translated(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  list-style: none;
  text-align: center;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const NavItems = styled.li`
  height: 80px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NavLinks = styled(LinkS)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 3rem;
  cursor: pointer;

  &:active {
    border-bottom: 3px solid var(--red);
  }
`;

export const LogLink = styled(LinkR)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 2.5rem;
  border-radius: 5px;
  cursor: pointer;
  background: var(--red);
  /* border: 2px solid var(--red); */
  &:hover {
    color: var(--black);
    background: var(--white);
  }
`;
