import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  ActionBtnWrapper,
  DeleteBtn,
  EditBtn,
  SearchInput,
  TableFooter,
  ViewDataContainer,
} from "./ViewDataElements";
import {
  makeStyles,
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
} from "@material-ui/core";
import { admin, currentUser } from "../../Auth/AuthContext";

const ViewData = () => {
  // <---------------------------- MUI Table Styles ------------------------------->

  const useStyles = makeStyles({
    tableContainer: {
      borderRadius: 15,
      margin: "10px 10px",
      maxWidth: "95%",
      height: "90vh",
      backgroundColor: "var(--white)",
    },
    tableHeaderCell: {
      fontWeight: "bold",
      backgroundColor: "var(--black)",
      color: "var(--white)",
      padding: "auto",
      position: "sticky",
      top: "0",
    },
    tableRow: {
      width: "auto",
      height: "0.5rem",
      "&:hover": {
        backgroundColor: "var(--light-gray)",
      },
    },
    tableCell: {
      padding: "10px 40px 10px 40px",
    },
  });
  const classes = useStyles();

  // <--------------------------- Fetching Data to the Table ---------------------->

  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState([]);
  const [search, setSearch] = useState("");
  const getDetails = async () => {
    try {
      axios.get("/api/view-details").then((res) => {
        if (res.status === 200) {
          setDetails(res.data.detail);
          console.log(res);
          setLoading(false);
        }
      });
    } catch {}
  };

  useEffect(() => {
    getDetails();
  }, []);

  // <---------------------------- Delete Data from the Table --------------------->

  const deleteDetail = (e, id) => {
    e.preventDefault();
    if (!window.confirm("Are You Sure To Delete This Data?")) {
      return;
    }
    const thisCliked = e.currentTarget;
    thisCliked.innerText = "Deleting";

    axios.delete(`api/delete-details/${id}`).then((res) => {
      if (res.data.status === 200) {
        alert(res.data.message);
        thisCliked.closest("tr").remove();
      } else if (res.data.status === 404) {
        alert(res.data.message);
        thisCliked.innerText = "Delete";
      }
    });
  };

  // <---------------------------- Pagination to the Table ------------------------>

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <ViewDataContainer>
      <TableFooter>
        <SearchInput
          placeholder="Search File / Invoice No"
          onChange={(e) => setSearch(e.target.value)}
        />
        <TablePagination
          rowsPerPageOptions={[20, 30, 40]}
          component="div"
          count={details.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableFooter>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table
          aria-label="simple table"
          fixedHeader={true}
          style={{ tableLayout: "auto" }}
        >
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeaderCell} align="left">
                File&nbsp;No
              </TableCell>

              <TableCell
                className={classes.tableHeaderCell}
                align="left"
                style={{ padding: "10px 90px 10px 90px" }}
              >
                Vessel&nbsp;Name
              </TableCell>
              <TableCell className={classes.tableHeaderCell} align="center">
                Anchorage&nbsp;Time
              </TableCell>
              <TableCell className={classes.tableHeaderCell} align="center">
                Anchorage&nbsp;Date
              </TableCell>
              <TableCell className={classes.tableHeaderCell} align="center">
                Departure&nbsp;Time
              </TableCell>
              <TableCell className={classes.tableHeaderCell} align="center">
                Departure&nbsp;Date
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                style={{ padding: "10px 90px 10px 90px" }}
              >
                Purpose
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                style={{ padding: "10px 80px 10px 80px" }}
              >
                Berthing&nbsp;Position
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                style={{ padding: "10px 120px 10px 120px" }}
              >
                CMB&nbsp;Agents
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                style={{ padding: "10px 50px 10px 50px" }}
              >
                S.L.P.A&nbsp;Ref&nbsp;No
              </TableCell>
              <TableCell className={classes.tableHeaderCell} align="center">
                Flag
              </TableCell>
              <TableCell className={classes.tableHeaderCell} align="center">
                L.O.A
              </TableCell>
              <TableCell className={classes.tableHeaderCell} align="center">
                G.R.T
              </TableCell>
              <TableCell className={classes.tableHeaderCell} align="center">
                N.R.T
              </TableCell>
              <TableCell className={classes.tableHeaderCell} align="center">
                IMO&nbsp;No
              </TableCell>
              <TableCell className={classes.tableHeaderCell} align="center">
                Call&nbsp;Sign
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                style={{ padding: "10px 50px 10px 50px" }}
              >
                Invoice&nbsp;No
              </TableCell>
              <TableCell className={classes.tableHeaderCell} align="center">
                Invoice&nbsp;Date
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                style={{ padding: "10px 100px 10px 100px" }}
              >
                Invoice&nbsp;Description
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="right"
                style={{ padding: "10px 50px 10px 50px" }}
              >
                Invoice&nbsp;Amount&nbsp;USD
              </TableCell>
              <TableCell className={classes.tableHeaderCell} align="right">
                Exchange&nbsp;Rate
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="right"
                style={{ padding: "10px 50px 10px 50px" }}
              >
                Invoice&nbsp;USD&nbsp;to&nbsp;LKR
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="right"
                style={{ padding: "10px 50px 10px 50px" }}
              >
                Invoice&nbsp;Amount&nbsp;LKR
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="right"
                style={{ padding: "10px 60px 10px 60px" }}
              >
                Invoice&nbsp;Total
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="right"
                style={{ padding: "10px 40px 10px 40px" }}
              >
                Payment&nbsp;Recieved
              </TableCell>
              <TableCell className={classes.tableHeaderCell} align="center">
                Payment&nbsp;Date
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="right"
                style={{ padding: "10px 70px 10px 70px" }}
              >
                Balance
              </TableCell>
              <TableCell className={classes.tableHeaderCell} align="center">
                Cheque&nbsp;No&nbsp;/&nbsp;Online
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                style={{ padding: "10px 120px 10px 120px" }}
              >
                Remarks
              </TableCell>
              <TableCell className={classes.tableHeaderCell} align="center">
                Data&nbsp;Entered&nbsp;By
              </TableCell>
              <TableCell className={classes.tableHeaderCell} align="center">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <h1>Loading...</h1>
          ) : (
            <TableBody>
              {details
                .filter(
                  (details) =>
                    details.invoiceNo.toString().includes(search) +
                    details.fileNo.toString().includes(search) +
                    details.vesselName.toString().includes(search) +
                    details.cmbAgents.toString().includes(search)
                )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item) => (
                  <TableRow key={item.id} className={classes.tableRow}>
                    <TableCell align="left">{item.fileNo}</TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      {item.vesselName}
                    </TableCell>
                    <TableCell align="center">{item.anchorageTime}</TableCell>
                    <TableCell align="center">{item.anchorageDate}</TableCell>
                    <TableCell align="center">{item.departureTime}</TableCell>
                    <TableCell align="center">{item.departureDate}</TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {item.purpose}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {item.berthingPosition}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {item.cmbAgents}
                    </TableCell>
                    <TableCell align="center">{item.slpaRefNo}</TableCell>
                    <TableCell align="center">{item.flag}</TableCell>
                    <TableCell align="center">{item.loa}</TableCell>
                    <TableCell align="center">{item.grt}</TableCell>
                    <TableCell align="center">{item.nrt}</TableCell>
                    <TableCell align="center">{item.imoNo}</TableCell>
                    <TableCell align="center">{item.callSign}</TableCell>
                    <TableCell align="center">{item.invoiceNo}</TableCell>
                    <TableCell align="center">{item.invoiceDate}</TableCell>
                    <TableCell align="center">{item.invoiceDesc}</TableCell>
                    <TableCell align="left">
                      $&nbsp;&nbsp;&nbsp;
                      {item.invoiceAmountUSD.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell align="left">
                      Rs&nbsp;&nbsp;&nbsp;
                      {item.exchangeRate.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell align="left">
                      Rs&nbsp;&nbsp;&nbsp;
                      {(
                        item.exchangeRate * item.invoiceAmountUSD
                      ).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell align="left">
                      Rs&nbsp;&nbsp;&nbsp;
                      {item.invoiceAmountSLR.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell align="left">
                      Rs&nbsp;&nbsp;&nbsp;
                      {item.invoiceTotal.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell align="left">
                      Rs&nbsp;&nbsp;&nbsp;
                      {item.paymentRecieved.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell align="center">{item.paymentDate}</TableCell>
                    <TableCell align="left">
                      Rs&nbsp;&nbsp;&nbsp;
                      {item.balance.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell align="center">{item.chequeNoOnline}</TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {item.remark}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {item.enteredBy}
                    </TableCell>
                    <TableCell align="center">
                      <ActionBtnWrapper>
                        {currentUser === admin ? (
                          <DeleteBtn onClick={(e) => deleteDetail(e, item.id)}>
                            Delete
                          </DeleteBtn>
                        ) : (
                          ""
                        )}
                        {currentUser === item.enteredBy ||
                        currentUser === admin ? (
                          <EditBtn to={`/dashboard/edit-details/${item.id}`}>
                            Edit
                          </EditBtn>
                        ) : (
                          " "
                        )}
                      </ActionBtnWrapper>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </ViewDataContainer>
  );
};

export default ViewData;
