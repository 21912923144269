export const GalleryImgs = [
  { image: "../Public_Images/ship4.JPG" },
  { image: "../Public_Images/ship5.JPG" },
  { image: "../Public_Images/ship6.JPG" },
  { image: "../Public_Images/ship8.JPG" },
  { image: "../Public_Images/ship9.JPG" },
  { image: "../Public_Images/ship10.JPG" },
  { image: "../Public_Images/sivan.jpeg" },
  { image: "../Public_Images/ship11.JPG" },
  { image: "../Public_Images/ship12.JPG" },
];

export const SlidingImages = [
  { image: "../Public_Images/S1.jpg" },
  { image: "../Public_Images/S2.jpg" },
  { image: "../Public_Images/S3.jpg" },
  { image: "../Public_Images/S4.jpg" },
  { image: "../Public_Images/S5.jpg" },
  { image: "../Public_Images/S6.jpg" },
  { image: "../Public_Images/S7.jpg" },
];
