import React from "react";
import styled from "styled-components";

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--gray);
  background-color: var(--black);
  font-size: 12px;
  text-align: center;
  @media screen and (max-width: 1000px) {
    font-size: 12px;
  }
`;

const Link = styled.a`
  text-decoration: none;
  color: var(--white);
  margin-top: 5px;
  margin-bottom: 10px;
`;

const Rights = () => {
  return (
    <RightSection>
      <p>{new Date().getFullYear()} © Victory Shipping</p>
      <Link href="https://www.facebook.com/techbrainsitsolutions">
        Developed By TechBrains
      </Link>
    </RightSection>
  );
};

export default Rights;
