import styled from "styled-components";

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white);
  min-height: 100vh;
  @media screen and (max-width: 800px) {
    padding: 4rem 0;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  z-index: 1;
  width: 100%;
  max-width: 1500px;
  justify-content: center;
  align-items: center;
`;

export const InfoRow = styled.div`
  display: flex;
  max-width: 1080px;
  grid-auto-columns: minmax(200px, 1fr);
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2' 'col1'` : `'col1' 'col2'`};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
    display: flex;
    margin: 1px;
    flex-direction: column;
  }
`;

export const Column1 = styled.div`
  margin-bottom: 10px;
  padding: 0 5px;
  grid-area: col1;
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 25px;
  grid-area: col2;
`;

export const TextWrapper = styled.div`
  width: 100%;
  padding: 1rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
`;

export const TopLine = styled.p`
  color: var(--red);
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
`;

export const Heading = styled.p`
  color: black;
  max-width: 600px;
  font-size: 46px;
  line-height: 1.1;
  font-weight: 600;
  color: black;

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Subtitle = styled.p`
  max-width: 500px;
  text-align: justify;
  font-size: 16px;
  line-height: 24px;
  color: black;
`;

export const BtnWrap = styled.p`
  display: flex;
  justify-content: flex-start;
`;

export const ImgWrap = styled.div`
  max-width: 600px;
`;

export const Img = styled.img`
  width: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  border-radius: 15px;
`;
