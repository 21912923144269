import React, { useState } from "react";
import {
  AuthBg,
  AuthButton,
  AuthContainer,
  AuthContent,
  AuthError,
  AuthErrorWrap,
  AuthForm,
  AuthH1,
  AuthInput,
  ForgotPasswordMsg,
  Span,
  VideoBg,
} from "./AuthElements";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Video from "../../videos/container2.mp4";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "../../App.css";

const ResetPassword = () => {
  // <------------------------------------ Password Eye Toggle ---------------------------->
  const navigate = useNavigate();
  const [eyeIcon, setEyeIcon] = useState(FaEyeSlash);
  const [type, setType] = useState("password");
  const [values, setValues] = useState({
    password: "",
    confirmPassword: "",
    error_list: [],
  });

  const handleToggle = () => {
    if (type === "password") {
      setEyeIcon(FaEye);
      setType("text");
    } else {
      setEyeIcon(FaEyeSlash);
      setType("password");
    }
  };

  // <------------------------------------ Reset Password Function ---------------------------->
  const { token } = useParams();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      password: values.password,
      password_confirmation: values.confirmPassword,
    };

    axios.get("/sanctum/csrf-cookie").then((response) => {
      console.log(response);

      axios.post(`api/reset-password/${token}`, data).then((res) => {
        if (res.data.status === 401) {
          alert(res.data.message);
          setValues({ ...values, error_list: "" });
        } else if (res.data.status === 200) {
          alert(res.data.message);
          navigate("/login");
        } else {
          setValues({ ...values, error_list: res.data.validation_errors });
        }
      });
    });
  };

  return (
    <AuthContainer id="hero">
      <AuthBg>
        <VideoBg autoPlay loop muted src={Video} type="video/mp4" />
      </AuthBg>
      <AuthContent>
        <AuthForm onSubmit={handleSubmit}>
          <AuthH1>Reset Password</AuthH1>
          <ForgotPasswordMsg>Create your new password</ForgotPasswordMsg>
          <AuthErrorWrap>
            {values.error_list.password && (
              <AuthError>{values.error_list.password}</AuthError>
            )}
          </AuthErrorWrap>
          <div style={{ position: "relative", left: "8px" }}>
            <AuthInput
              type={type}
              name="password"
              placeholder="Password"
              onChange={handleChange}
              value={values.password}
            />
            <Span onClick={handleToggle}>{eyeIcon}</Span>
          </div>

          <div style={{ position: "relative", left: "8px" }}>
            <AuthInput
              type={type}
              name="confirmPassword"
              placeholder="Confirm Password"
              onChange={handleChange}
              value={values.confirmPassword}
            />
            <Span onClick={handleToggle}>{eyeIcon}</Span>
          </div>
          <AuthButton>Reset Password</AuthButton>
          <Link
            className="link"
            to="/"
            style={{
              marginTop: "15px",
            }}
          >
            Back to Home
          </Link>
        </AuthForm>
      </AuthContent>
    </AuthContainer>
  );
};

export default ResetPassword;
