import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { currentUser } from "../../Auth/AuthContext.js";
import {
  AddDataForm,
  FormButton,
  FormFooter,
  BackLink,
  DetailWrapper,
} from "../AddData/AddDataElements.jsx";
import EditInvoice from "./EditInvoice.jsx";
import EditShipping from "./EditShipping.jsx";

function EditData() {
  const [formData, setFormData] = useState({
    fileNo: "",
    vesselName: "",
    anchorageTime: "",
    anchorageDate: "",
    departureTime: "",
    departureDate: "",
    purpose: "",
    berthingPosition: "",
    cmbAgents: "",
    slpaRefNo: "",
    flag: "",
    loa: "",
    grt: "",
    nrt: "",
    imoNo: "",
    callSign: "",
    invoiceNo: "",
    invoiceDate: "",
    invoiceDesc: "",
    invoiceAmountSLR: "",
    invoiceTotal: "",
    invoiceAmountUSD: "",
    exchangeRate: "",
    paymentRecieved: "",
    paymentDate: "",
    chequeNoOnline: "",
    balance: "",
    remark: "",
    enteredBy: "",
  });

  const [errors, setErrors] = useState([]);

  // <---------------------------- Get data to Edit ----------------------->

  const { detail_id } = useParams();
  useEffect(() => {
    axios.get(`/api/edit-details/${detail_id}`).then((res) => {
      if (res.data.status === 200) {
        setFormData(res.data.detail);
      } else if (res.data.status === 404) {
        alert(res.data.message);
      }
    });
  }, [detail_id]);

  // <---------------------------- Updating the data ----------------------->

  const navigate = useNavigate();
  const updateDetails = (e) => {
    e.preventDefault();
    const data = formData;
    axios.put(`/api/update-details/${detail_id}`, data).then((res) => {
      if (res.data.status === 200) {
        alert(res.data.message);
        navigate("/dashboard/view_data");
      } else if (res.data.status === 422) {
        setErrors(res.data.errors);
        alert(errors.fileNo);
      } else if (res.data.status === 404) {
        alert(res.data.message);
      }
    });
  };

  formData.enteredBy = currentUser;
  return (
    <AddDataForm onSubmit={updateDetails}>
      <DetailWrapper>
        <EditShipping formData={formData} setFormData={setFormData} />
        <EditInvoice formData={formData} setFormData={setFormData} />
      </DetailWrapper>
      <FormFooter>
        <BackLink to="/dashboard/view_data">Cancel</BackLink>
        <FormButton type="submit">Update</FormButton>
      </FormFooter>
    </AddDataForm>
  );
}

export default EditData;
