import React from "react";
import {
  FooterContainer,
  FooterWrap,
  FooterContent,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLinksContainer,
  FooterLinksWrapper,
} from "./FooterElements";
import Memof from "../../images/memof2.png";

const Footer = () => {
  return (
    <FooterContainer id="contactus">
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Member of</FooterLinkTitle>
              <FooterContent>
                <img
                  style={{ borderRadius: "10px" }}
                  src={Memof}
                  alt="memory of"
                />
              </FooterContent>
            </FooterLinkItems>

            <FooterLinkItems>
              <FooterLinkTitle>Contact us</FooterLinkTitle>
              <FooterContent>M/S. VICTORY AGENCIES</FooterContent>
              <FooterContent>NO: 101, DOCKYARD ROAD, TRINCOMALEE</FooterContent>
              <FooterContent>T.P: + 94 26 2222697</FooterContent>
              <FooterContent>FAX: + 94 26 2222743</FooterContent>
            </FooterLinkItems>

            <FooterLinkItems>
              <FooterLinkTitle>Email</FooterLinkTitle>
              <FooterContent>ops@victoryagencies.lk</FooterContent>
              <FooterContent>victorytco@sltnet.lk</FooterContent>
            </FooterLinkItems>

            <FooterLinkItems>
              <FooterLinkTitle>Bank Details</FooterLinkTitle>
              <FooterContent>Hatton National Bank, Trincomalee</FooterContent>
            </FooterLinkItems>

            <FooterLinkItems>
              <FooterLinkTitle>Audit Details</FooterLinkTitle>
              <FooterContent>
                Jawaamil Associates (Chartered Accountants)
              </FooterContent>
              <FooterContent>
                JSA Associates (Chartered Accountants)
              </FooterContent>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
