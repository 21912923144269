import React from "react";
import "./Rights.css";

const Rights = () => {
  return (
    <div className="rights">
      <p style={{marginTop: "100px" }}>
        Copyright © Victory Shipping {new Date().getFullYear()}. All rights reserved.
      </p>
      <a href="https://www.facebook.com/techbrainsitsolutions" className="link"> Developed By TechBrains. </a>
    </div>
  );
};

export default Rights;