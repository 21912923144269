import React, { useState } from "react";
import "./ImgSection.css";
import { SlidingImages } from "../../Data";
import styled from "styled-components";
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from "react-icons/fa";

const HerSliderWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: var(--white);
  position: relative;
`;

const Image = styled.img`
  width: 80%;
  height: 80vh;
  border-radius: 20px;
  object-fit: cover;
  -o-object-fit: cover;
  @media screen and (max-width: 900px) {
    width: 90%;
  }
`;

const ImgSection = ({ slides }) => {
  const [current, setCurrent] = useState(0);
  const length = slides.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  return (
    <HerSliderWrapper>
      <FaArrowAltCircleLeft className="left-arrow" onClick={prevSlide} />
      {SlidingImages.map((slide, index) => (
        <React.Fragment key={index}>
          {index === current && (
            <Image src={slide.image} alt="sliding-images" height={"30px"} />
          )}
        </React.Fragment>
      ))}
      <FaArrowAltCircleRight onClick={nextSlide} className="right-arrow" />
    </HerSliderWrapper>
  );
};

export default ImgSection;
