import React from "react";
import {
  FounderContainer,
  FounderH1,
  FounderWrapper,
  FounderCard,
  FounderH2,
  FounderIcon,
  FounderP,
} from "./FounderElements";
import Icon1 from "../../images/man2.JPG"
import Icon2 from "../../images/man1.JPG"

const Founder = () => {
  return (
    <FounderContainer id="Founder">
      <FounderH1>Our Founder & Director</FounderH1>
      <FounderWrapper>
        <FounderCard>
          <FounderIcon 
          src={Icon1} 
          />
          <FounderH2>Mr. K. Sathiyaseelarasa</FounderH2>
          <FounderP>Founder of Victory Agencies-Shipping </FounderP>
        </FounderCard>

        <FounderCard>
          <FounderIcon 
          src={Icon2} 
          />
          <FounderH2>Mr. K. Sajeevan</FounderH2>
          <FounderP>Managing Director of Victory Agencies-Shipping</FounderP>
        </FounderCard>
      </FounderWrapper>
    </FounderContainer>
  );
};

export default Founder;