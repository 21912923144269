import React from "react";
import {
  HeroBg,
  HeroContainer,
  VideoBg,
  HeroP,
  HeroH1,
  HeroContent,
} from "./HeroElements";
import Video from "../../videos/container2.mp4";

const HeroSection = () => {
  return (
    <HeroContainer id="hero">
      <HeroBg>
        <VideoBg
          autoPlay
          loop
          muted
          playsInline
          webkit-playsinline
          src={Video}
          type="video/m4v"
        />
      </HeroBg>
      <HeroContent>
        <HeroH1>
          WE, <br /> VICTORY AGENCIES (PVT) LTD TRINCOMALEE
        </HeroH1>
        <HeroP>is pleased to introduce our profession to you</HeroP>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
