import React, { useState } from "react";
import {
  AuthButton,
  AuthError,
  AuthErrorWrap,
  AuthForm,
  AuthH1,
  AuthInput,
} from "./AuthElements";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import axios from "axios";

const SignupForm = () => {
  const navigate = useNavigate();
  const [eyeIcon, setEyeIcon] = useState(FaEyeSlash);
  const [type, setType] = useState("password");
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    error_list: [],
  });

  const handleToggle = () => {
    if (type === "password") {
      setEyeIcon(FaEye);
      setType("text");
    } else {
      setEyeIcon(FaEyeSlash);
      setType("password");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  function handleSubmit(e) {
    e.preventDefault();

    const data = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      password: values.password,
    };

    axios.get("/sanctum/csrf-cookie").then((response) => {
      console.log(response);
      axios.post(`api/signup`, data).then((res) => {
        if (res.data.status === 200) {
          localStorage.setItem("auth_token", res.data.token);
          localStorage.setItem("auth_name", res.data.username);
          localStorage.setItem("auth_email", res.data.email);
          localStorage.setItem("auth_lastname", res.data.lastname);
          navigate("/dashboard/view_data");
          window.location.reload();
        } else {
          setValues({ ...values, error_list: res.data.validation_errors });
        }
      });
    });
  }

  return (
    <AuthForm onSubmit={handleSubmit}>
      <AuthH1>Create User</AuthH1>
      <AuthInput
        type="text"
        id="first_name"
        name="first_name"
        placeholder="First Name"
        value={values.first_name}
        onChange={handleChange}
      />
      <AuthErrorWrap>
        {values.error_list.first_name && (
          <AuthError>{values.error_list.first_name}</AuthError>
        )}
      </AuthErrorWrap>{" "}
      <AuthInput
        type="text"
        id="last_name"
        name="last_name"
        placeholder="Last Name"
        onChange={handleChange}
        value={values.last_name}
      />
      <AuthErrorWrap>
        {values.error_list.last_name && (
          <AuthError>{values.error_list.last_name}</AuthError>
        )}
      </AuthErrorWrap>
      <AuthInput
        type="email"
        id="email"
        name="email"
        placeholder="Email Address"
        onChange={handleChange}
        value={values.email}
      />
      <AuthErrorWrap>
        {values.error_list.email && (
          <AuthError>{values.error_list.email}</AuthError>
        )}
      </AuthErrorWrap>{" "}
      <div style={{ position: "relative", left: "8px" }}>
        <AuthInput
          type={type}
          id="password"
          name="password"
          placeholder="Password"
          onChange={handleChange}
          value={values.password}
        />
        <span
          style={{
            position: "relative",
            fontSize: "medium",
            color: "#000",
            cursor: "pointer",
            right: "28px",
            top: "4px",
          }}
          onClick={handleToggle}
        >
          {eyeIcon}
        </span>
      </div>
      <AuthErrorWrap>
        {values.error_list.password && (
          <AuthError>{values.error_list.password}</AuthError>
        )}
      </AuthErrorWrap>{" "}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "17rem",
        }}
      ></div>
      <AuthButton>Create</AuthButton>
      <Link
        className="link"
        to="/login"
        style={{
          marginTop: "15px",
        }}
      >
        Login Instant
      </Link>
    </AuthForm>
  );
};

export default SignupForm;
