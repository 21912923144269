import React from "react";
import { BlogContainer, BlogHeading, BlogRow } from "./BlogElements";

const Blog = () => {
  return (
    <BlogContainer id="blog">
      <BlogHeading>Blog</BlogHeading>
      <BlogRow>
        Our shipping agency services was specifically designed to meet the
        growing demand for agency services that exceeds traditional
        expectations. We offer value added services with a specific focus on
        customer needs. Our clients usually demand higher levels of service from
        us than traditional agency company. This gives us the opportunity to
        excel in our services provided to our customers in order to meet their
        high standards.
      </BlogRow>
      <BlogRow>
        Our operational and management team have been selected for their broad
        knowledge of the industry, their dedication to customer service and for
        their motivation to strive for the best results for our clients.
      </BlogRow>
      <BlogRow>
        We open up opportunities to earn the trust of owners and charterers. We
        aim to create long term business relationships and we have always been
        guided by our commitment to responsible behavior. We believe in
        fostering a culture of excellence and collaboration among our staff and
        crew. Attention to safety, security and compliance is very much a part
        of VICTORY AGENCIES staff and culture.
      </BlogRow>
    </BlogContainer>
  );
};

export default Blog;
