import React from "react";
import {
  ConvertValue,
  ExchangeWrapper,
  FormContainer,
  FormInput,
  FormLongInput,
  FormSection,
  FormTextArea,
  FormWrapper,
  H2,
  InputWrapper,
  InvoiceWrapper,
  Label,
} from "../AddData/AddDataElements.jsx";

const EditInvoice = ({ formData, setFormData }) => {
  var convertValue = 0;
  convertValue = formData.invoiceAmountUSD * formData.exchangeRate;
  return (
    <FormContainer>
      <H2>Invoice Details</H2>
      <FormWrapper>
        <FormSection>
          <InvoiceWrapper>
            <InputWrapper>
              <Label>Invoice No</Label>
              <FormInput
                type="text"
                required
                value={formData.invoiceNo}
                onChange={(e) =>
                  setFormData({ ...formData, invoiceNo: e.target.value })
                }
              />
            </InputWrapper>
            <InputWrapper>
              <Label>Invoice Date</Label>
              <FormLongInput
                type="date"
                style={{ width: "15rem" }}
                value={formData.invoiceDate}
                required
                onChange={(e) =>
                  setFormData({ ...formData, invoiceDate: e.target.value })
                }
              />
            </InputWrapper>
          </InvoiceWrapper>
          <InvoiceWrapper>
            <ExchangeWrapper>
              <InputWrapper style={{ width: "8rem" }}>
                <Label>Invoice Amnt (USD)</Label>
                <FormInput
                  style={{ padding: "0.4rem", width: "9rem" }}
                  type="number"
                  placeholder="USD"
                  required
                  min={0}
                  step={0.01}
                  value={formData.invoiceAmountUSD}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      invoiceAmountUSD: e.target.value,
                    })
                  }
                />
              </InputWrapper>
              <InputWrapper style={{ marginLeft: "10px" }}>
                <Label>Exchange</Label>
                <FormInput
                  style={{ width: "5rem", padding: "0.3rem" }}
                  type="float"
                  min={0}
                  placeholder="%"
                  required
                  value={formData.exchangeRate}
                  onChange={(e) =>
                    setFormData({ ...formData, exchangeRate: e.target.value })
                  }
                />
              </InputWrapper>
            </ExchangeWrapper>
            <InputWrapper>
              <Label>Converted LKR Value</Label>
              <ConvertValue>
                {formData.invoiceAmountUSD * formData.exchangeRate}
              </ConvertValue>
            </InputWrapper>
          </InvoiceWrapper>
          <InvoiceWrapper>
            <InputWrapper>
              <Label>Payment Recieved Amount</Label>
              <FormInput
                type="number"
                placeholder="LKR"
                min={0}
                step={0.01}
                required
                value={formData.paymentRecieved}
                onChange={(e) =>
                  setFormData({ ...formData, paymentRecieved: e.target.value })
                }
              />
            </InputWrapper>
            <InputWrapper>
              <Label>Payment Recieved Date</Label>
              <FormLongInput
                type="date"
                style={{ width: "15rem" }}
                required
                value={formData.paymentDate}
                onChange={(e) =>
                  setFormData({ ...formData, paymentDate: e.target.value })
                }
              />
            </InputWrapper>
            <InputWrapper>
              <Label>Cheque No / Online</Label>
              <FormInput
                type="text"
                min={0}
                required
                value={formData.chequeNoOnline}
                onChange={(e) =>
                  setFormData({ ...formData, chequeNoOnline: e.target.value })
                }
              />
            </InputWrapper>
          </InvoiceWrapper>
        </FormSection>
        <FormSection>
          <InvoiceWrapper>
            <InputWrapper>
              <Label>Invoice Description</Label>
              <FormTextArea
                type="text"
                value={formData.invoiceDesc}
                required
                onChange={(e) =>
                  setFormData({ ...formData, invoiceDesc: e.target.value })
                }
              ></FormTextArea>
            </InputWrapper>
          </InvoiceWrapper>

          <InvoiceWrapper>
            <InputWrapper>
              <Label>Invoice Amount (SLR)</Label>
              <FormInput
                type="number"
                placeholder="LKR"
                min={0}
                step={0.01}
                required
                value={formData.invoiceAmountSLR}
                onChange={(e) =>
                  setFormData({ ...formData, invoiceAmountSLR: e.target.value })
                }
              />
            </InputWrapper>
            <InputWrapper>
              <Label>Invoice Total Amount</Label>
              <ConvertValue>
                {
                  (formData.invoiceTotal =
                    parseFloat(convertValue) +
                    parseFloat(formData.invoiceAmountSLR))
                }
              </ConvertValue>
            </InputWrapper>
          </InvoiceWrapper>

          <InvoiceWrapper>
            <InputWrapper>
              <Label>Balance</Label>
              <ConvertValue>
                {
                  (formData.balance =
                    formData.invoiceTotal - formData.paymentRecieved)
                }
              </ConvertValue>
            </InputWrapper>
            <InputWrapper>
              <Label>Remarks</Label>
              <FormTextArea
                type="text"
                value={formData.remark}
                required
                onChange={(e) =>
                  setFormData({ ...formData, remark: e.target.value })
                }
              />
            </InputWrapper>
          </InvoiceWrapper>
        </FormSection>
      </FormWrapper>
    </FormContainer>
  );
};

export default EditInvoice;
