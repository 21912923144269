import axios from "axios";
import React, { useState } from "react";
import {
  AddFieldContainer,
  AddFieldFormWrapper,
  AddFieldFrom,
  FieldInputWrapper,
  FieldButton,
  FieldInput,
  Label,
  AddFieldH1,
} from "./AddFieldElements.jsx";
import { ErrorText } from "../AddData/AddDataElements.jsx";

const AddField = () => {
  const [shipname, setShipname] = useState("");
  const [agent, setAgent] = useState("");
  const [errorList, setErrorList] = useState([]);

  // <---------------------------- Store Ship Names --------------------------->

  const handleShip = (e) => {
    e.preventDefault();

    const data = {
      shipname: shipname,
    };

    axios.post("/api/store-shipnames", data).then((res) => {
      if (res.data.status === 200) {
        setShipname("");
        setErrorList("");
        alert(res.data.message);
      } else if (res.data.status === 400) {
        setErrorList(res.data.errors);
      }
    });
  };

  // <---------------------------- Store Agent Names --------------------------->

  const handleAgent = (e) => {
    e.preventDefault();

    const data = {
      agentname: agent,
    };

    axios.post("/api/store-agentnames", data).then((res) => {
      if (res.data.status === 200) {
        setAgent("");
        setErrorList("");
        alert(res.data.message);
      } else if (res.data.status === 400) {
        setErrorList(res.data.errors);
      }
    });
  };

  return (
    <AddFieldContainer>
      <AddFieldFormWrapper>
        <AddFieldH1>Create New Fields</AddFieldH1>
        <AddFieldFrom style={{ marginTop: "25px" }} onSubmit={handleShip}>
          <Label>Create New Vessel Name</Label>
          <FieldInputWrapper>
            <FieldInput
              required
              type="text"
              placeholder="New Ship Name"
              value={shipname}
              onChange={(e) => setShipname(e.target.value)}
            />
            <FieldButton>Create</FieldButton>
          </FieldInputWrapper>
          <ErrorText>{errorList.shipname}</ErrorText>
        </AddFieldFrom>
        <AddFieldFrom onSubmit={handleAgent}>
          <Label>Create New CMB Agent Name</Label>
          <FieldInputWrapper>
            <FieldInput
              required
              type="text"
              placeholder="New Colombo Agent Name"
              value={agent}
              onChange={(e) => setAgent(e.target.value)}
              // onChange={handleInput}
              name="agentname"
            />
            <FieldButton>Create</FieldButton>
          </FieldInputWrapper>
          <ErrorText>{errorList.agentname}</ErrorText>
        </AddFieldFrom>
      </AddFieldFormWrapper>
    </AddFieldContainer>
  );
};

export default AddField;
