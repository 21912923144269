import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import axios from "axios";
import {
  Login,
  Signup,
  ViewData,
  AddData,
  UserDetails,
  AddField,
  ForgotPassword,
  ResetPassword,
} from "./Admin";
import SharedLayout from "./Admin/Dashboard/SharedLayout";
import Error from "./pages/Error";
import EditData from "./Admin/Dashboard/EditData/EditData";
import { auth, admin, currentUser } from "./Admin/Auth/AuthContext";

// axios.defaults.baseURL = "http://127.0.0.1:8000/";
axios.defaults.baseURL = "https://backend.victoryagencies.lk/";

https: axios.defaults.headers.post["Content-Type"] = "application/JSON";
axios.defaults.headers.post["Accept"] = "application/JSON";

axios.defaults.withCredentials = true;
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("auth_token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="*" element={<Error />} />
        <Route
          path="/login"
          element={auth ? <Navigate to="/dashboard/add_data" /> : <Login />}
        />
        <Route
          path="/admin/signup"
          element={auth ? <Navigate to="/dashboard/add_data" /> : <Signup />}
        />
        <Route
          path="/forgotPassword"
          element={
            auth ? <Navigate to="/dashboard/add_data" /> : <ForgotPassword />
          }
        />
        <Route
          path="/api/user/reset/:token"
          element={
            auth ? <Navigate to="/dashboard/add_data" /> : <ResetPassword />
          }
        />
        <Route path="dashboard" element={<SharedLayout />}>
          <Route path="add_data" element={<AddData />} />
          <Route path="new_field" element={<AddField />} />
          <Route path="view_data" element={<ViewData />} />
          <Route path="edit-details/:detail_id" element={<EditData />} />
          <Route
            path="user_details"
            element={
              currentUser === admin ? (
                <UserDetails />
              ) : (
                <Navigate to="/dashboard/add_data" />
              )
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
