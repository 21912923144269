export const homeObjOne = {
  id: "about",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "About us",
  headline: "VICTORY SHIPPING",
  description:
    "Our victory shipping services was established in 1980 now renamed as VICTORY AGENCIES (PVT) Ltd, with effect from 1999. The company monitors and assesses more than 1100 vessels (Including Bunkering Operation at Back Bay, Crew change, Discharging Operation, Passenger Vessels, Naval Ships & Project Cargo) (treats the vessels as their own) during this period. ",
  imgStart: false,
  img: require("../../images/Trinco-ship1.jpeg"),
  alt: "Ship",
  dark: true,
  primary: true,
  darkText: true,
};
