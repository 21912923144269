import styled from "styled-components";

export const FounderContainer = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: var(--white);
  padding: 2rem 1rem;
`;

export const FounderWrapper = styled.div`
  margin: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  padding: 0 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

export const FounderCard = styled.div`
  background: var(--white);
  border: 1px solid #d2d2d2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  height: 300px;
  padding: 30px;

  &:hover {
    background: #d2d2d2;
  }
`;

export const FounderIcon = styled.img`
  height: 10rem;
  width: 8rem;
  margin-bottom: 10px;
  object-fit: cover;
  -o-object-fit: cover;
`;

export const FounderH1 = styled.h1`
  font-size: 2.5rem;
  /* color: black; */
  margin-bottom: 64px;
  text-align: center;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
    margin-top: 20px;
  }
`;

export const FounderH2 = styled.h2`
  font-size: 1rem;
  margin-bottom: 10px;
`;

export const FounderP = styled.p`
  font-size: 1rem;
  text-align: center;
`;
