import { Link } from "react-router-dom";
import styled from "styled-components";

export const ViewDataContainer = styled.section`
  width: 85%;
  background-color: var(--light-gray);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  @media screen and (max-width: 720px) {
    width: 85%;
  }
`;

export const TableWrapper = styled.div`
  width: 95%;
  border: 2px solid var(--black);
  height: 80vh;
  overflow: hidden;
  overflow-x: scroll;
  overflow-y: scroll;
`;

export const Table = styled.table`
  display: flex;
  flex-direction: column;
`;

export const ActionBtnWrapper = styled.div`
  display: flex;
  height: 8px;
  align-items: center;
`;

export const DeleteBtn = styled.button`
  border: none;
  border-radius: 5px;
  color: var(--white);
  background: var(--delete);
  padding: 0.5rem 1rem;
  margin-left: 5px;
  cursor: pointer;
  font-weight: 600;
  font-size: 10px;
`;

export const EditBtn = styled(Link)`
  border: none;
  border-radius: 5px;
  color: var(--white);
  background: var(--blue);
  padding: 0.5rem 1rem;
  margin-left: 5px;
  cursor: pointer;
  font-weight: 600;
  font-size: 10px;
  text-decoration: none;
`;

export const TableFooter = styled.footer`
  display: flex;
  width: 95%;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 720px) {
    justify-content: center;
    flex-direction: column;
    margin-top: 15px;
  }
`;

export const SearchInput = styled.input`
  padding: 1rem;
  height: 2rem;
  width: 15rem;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  color: var(--black);
  background-color: var(--white);

  &:focus {
    outline: 2px solid black;
  }

  @media screen and (max-width: 560px) {
    width: 100%;
  }
`;
