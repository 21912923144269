import styled from "styled-components";

export const AddFieldContainer = styled.div`
  width: 86%;
  background-color: var(--light-gray);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }
`;

export const AddFieldFormWrapper = styled.main`
  width: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const AddFieldFrom = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 25px;
`;

export const AddFieldH1 = styled.h1`
  @media screen and (max-width: 900px) {
    font-size: 28px;
  }
`;

export const Label = styled.label`
  font-size: 12px;
  margin-bottom: 5px;
`;

export const FieldInput = styled.input`
  width: 18rem;
  padding: 0.6rem;
  border-radius: 5px;
  color: var(--black);
  background-color: var(--white);
  border: none;
  border-radius: 5px;

  @media screen and (max-width: 900px) {
    margin: 10px 0;
    width: 15rem;
  }
`;

export const FieldInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: auto;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const FieldButton = styled.button`
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  color: var(--white);
  background: var(--black);
  width: 6rem;
  cursor: pointer;

  @media screen and (max-width: 900px) {
    margin: 10px 0;
    width: 15rem;
    padding: 0.6rem;
  }
`;
