import styled from "styled-components";

export const BlogContainer = styled.div`
  background: var(--white);
  color: black;
  min-height: 100vh;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

export const BlogHeading = styled.p`
  margin: 2rem 0;
  max-width: 600px;
  font-size: 48px;
  font-weight: 600;

  @media screen and (max-width: 800px) {
    font-size: 40px;
  }
`;

export const BlogRow = styled.div`
  margin-bottom: 10px;
  text-align: justify;
  max-width: 80%;

  @media screen and (max-width: 768px) {
    font-size: 15px;
    max-width: 85%;
  }
`;
