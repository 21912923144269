import React from "react";
import {
  Logout,
  LogoutIcon,
  MobileIcon,
  SBar,
  SBarContainer,
  SBarH1,
  SBarItems,
  SBarLinks,
  SBarLogo,
  SbarMenu,
} from "./SidebarElements";
import Logo from "../../../../images/shipLogo1.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Rights from "../Rights/Rights";
import { admin, currentUser } from "../../../Auth/AuthContext";
import { FaBars } from "react-icons/fa";

const Sidebar = ({ toggled }) => {
  const navigate = useNavigate();
  const handleLogout = (e) => {
    e.preventDefault();
    axios.post(`/api/logout`).then((res) => {
      if (res.data.status === 200) {
        localStorage.removeItem("auth_token");
        localStorage.removeItem("auth_name");
        localStorage.removeItem("auth_email");
        localStorage.removeItem("auth_lastname");
        navigate("/");
        window.location.reload();
      }
    });
  };

  return (
    <SBar>
      <MobileIcon onClick={toggled}>
        <FaBars />
      </MobileIcon>
      <SBarContainer>
        <SBarLogo src={Logo} />
        <SBarH1>Dashboard</SBarH1>
        <p style={{ fontSize: "12px", margin: "5px 0px" }}>Logged in as :</p>
        <p style={{ fontSize: "13px", margin: "5px 0px" }}>{currentUser}</p>
        <SbarMenu>
          <SBarItems>
            <SBarLinks to="/dashboard/add_data">Add New Data</SBarLinks>
          </SBarItems>
          <SBarItems>
            <SBarLinks to="/dashboard/new_field">Create New Field</SBarLinks>
          </SBarItems>
          <SBarItems>
            <SBarLinks to="/dashboard/view_data">View All Data</SBarLinks>
          </SBarItems>
          {currentUser === admin ? (
            <SBarItems>
              <SBarLinks to="/dashboard/user_details">User Details</SBarLinks>
            </SBarItems>
          ) : (
            ""
          )}
        </SbarMenu>
      </SBarContainer>
      <SBarContainer>
        <Logout onClick={handleLogout}>
          <LogoutIcon />
          Logout
        </Logout>
        <Rights />
      </SBarContainer>
    </SBar>
  );
};

export default Sidebar;
