import React, { useState } from "react";
import Blog from "../components/Blog";
import Footer from "../components/Footer";
import Founder from "../components/Founder";
import Gallery from "../components/Gallery";
import HeroSection from "../components/HeroSection";
import InfoSection from "../components/InfoSection";
import { homeObjOne } from "../components/InfoSection/Data";
import Navbar from "../components/Navbar";
import Rights from "../components/Rights";
import Services from "../components/Services";
import Sidebar from "../components/Sidebar";
import ImgSection from "../components/ImgSection/ImgSection";
import { SlidingImages } from "../Data";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <InfoSection {...homeObjOne} />
      <ImgSection slides={SlidingImages} />
      <Services />
      <Blog />
      <Gallery />
      <Founder />
      <Footer />
      <Rights />
    </div>
  );
};

export default Home;
