import { Link } from "react-router-dom";
import styled from "styled-components";

export const AddDataForm = styled.form`
  width: 86%;
  background-color: var(--light-gray);
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }
`;

export const DetailWrapper = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const FormWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1250px) {
    flex-direction: column;
  }
`;

export const FormSection = styled.div`
  display: flex;
  width: 15rem;
  flex-direction: column;
  margin: 25px 20px 25px 0px;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const Label = styled.label`
  font-size: 13px;
  margin-bottom: 4px;
`;

export const FormInput = styled.input`
  padding: 1rem;
  height: 2rem;
  width: 15rem;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  color: var(--black);
  background-color: var(--white);

  &:focus {
    outline: 2px solid black;
  }
`;

export const TimeDateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: auto;
`;

export const ExchangeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 15rem;
`;

export const FormLongInput = styled.input`
  padding: 0.48rem;
  width: 6.8rem;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  color: var(--black);
  background-color: var(--white);

  &:focus {
    outline: 2px solid black;
  }
`;

export const FormTextArea = styled.textarea`
  padding: 0.5rem;
  height: 5.9rem;
  width: 15rem;
  border: none;
  font-size: 12px;
  border-radius: 5px;
  background-color: var(--white);

  &:focus {
    outline: 2px solid black;
  }
`;

export const FormFooter = styled.footer`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 900px) {
    flex-direction: column-reverse;
  }
`;

export const FormButton = styled.button`
  height: 2.5rem;
  width: 16rem;
  cursor: pointer;
  margin: 0px 30px 10px 10px;
  color: var(--white);
  background-color: var(--black);
  border: none;
  border-radius: 5px;
`;

export const InvoiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 15rem;
  margin: 0px 25px 0px 0px;
`;

export const ConvertValue = styled.div`
  width: 15rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  display: flex;
  border: none;
  background-color: var(--white);
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  color: var(--black);
`;

export const ErrorText = styled.p`
  font-size: 12px;
  color: var(--red);
  margin-top: 5px;
`;

export const FormSelect = styled.select`
  padding: 0.4rem;
  width: 15rem;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  color: var(--dark-gray);
  background-color: var(--white);
`;

export const FormOption = styled.option`
  padding: 0.48rem;
  width: 15rem;
  text-decoration: none;
  border: none;
  color: var(--black);
  background-color: var(--white);
`;

export const H2 = styled.h2`
  font-size: 22px;
  margin-top: 30px;
`;

export const BackLink = styled(Link)`
  text-decoration: none;
  height: 2.5rem;
  width: 16rem;
  cursor: pointer;
  margin: 0px 30px 10px 10px;
  color: var(--black);
  border: 2px solid black;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
