import React from "react";
import {
  GalleryContainer,
  GalleryWrapper,
  GalleryCard,
  GalleryIcon,
  GalleryH1,
} from "./GalleryElements";
import { GalleryImgs } from "../../Data";

const Gallery = () => {
  return (
    <GalleryContainer id="gallery">
      <GalleryH1>Our Gallery</GalleryH1>
      <GalleryWrapper>
        {GalleryImgs.map((item, index) => (
          <GalleryCard key={index}>
            <GalleryIcon src={item.image} alt="images" />
          </GalleryCard>
        ))}
      </GalleryWrapper>
    </GalleryContainer>
  );
};

export default Gallery;
