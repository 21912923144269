import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { admin, currentUser } from "../../../Auth/AuthContext";
import {
  Icon,
  CloseIcon,
  MobileContainer,
  MobileMenuBar,
  MobileWrapper,
  MobileLink,
  Logout,
  LogoutIcon,
} from "./MobileElements";

const MobileMenu = ({ isOpened, toggled }) => {
  const navigate = useNavigate();
  const handleLogout = (e) => {
    e.preventDefault();
    axios.post(`/api/logout`).then((res) => {
      if (res.data.status === 200) {
        localStorage.removeItem("auth_token");
        localStorage.removeItem("auth_name");
        localStorage.removeItem("auth_email");
        localStorage.removeItem("auth_lastname");
        navigate("/");
        window.location.reload();
      }
    });
  };
  return (
    <MobileContainer isOpened={isOpened} onClick={toggled}>
      <Icon onClick={toggled}>
        <CloseIcon />
      </Icon>
      <MobileWrapper>
        <MobileMenuBar>
          <MobileLink to="/dashboard/add_data" onClick={toggled}>
            Add Data
          </MobileLink>
          <MobileLink to="/dashboard/new_field" onClick={toggled}>
            Add New Field
          </MobileLink>
          <MobileLink to="/dashboard/view_data" onClick={toggled}>
            View Data
          </MobileLink>
          {currentUser === admin ? (
            <MobileLink to="/dashboard/user_details" onClick={toggled}>
              User Details
            </MobileLink>
          ) : (
            ""
          )}
          <Logout onClick={handleLogout}>
            <LogoutIcon />
            Logout
          </Logout>
        </MobileMenuBar>
      </MobileWrapper>
    </MobileContainer>
  );
};

export default MobileMenu;
