import React, { useState } from "react";
import {
  AuthBg,
  AuthButton,
  AuthContainer,
  AuthContent,
  AuthError,
  AuthErrorWrap,
  AuthForgotPassword,
  AuthForm,
  AuthH1,
  AuthInput,
  Span,
  VideoBg,
} from "./AuthElements";

import Video from "../../videos/container2.mp4";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import axios from "axios";
import swal from "sweetalert";
import "../../App.css";

const Login = () => {
  // <------------------------------------ Password Eye Toggle ---------------------------->
  const [eyeIcon, setEyeIcon] = useState(FaEyeSlash);
  const [type, setType] = useState("password");
  const [values, setValues] = useState({
    email: "",
    password: "",
    error_list: [],
  });

  const handleToggle = () => {
    if (type === "password") {
      setEyeIcon(FaEye);
      setType("text");
    } else {
      setEyeIcon(FaEyeSlash);
      setType("password");
    }
  };

  // <------------------------------------ Login Function ---------------------------->
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      email: values.email,
      password: values.password,
    };

    axios.get("/sanctum/csrf-cookie").then((response) => {
      console.log(response);

      axios.post(`api/login`, data).then((res) => {
        if (res.data.status === 200) {
          localStorage.setItem("auth_token", res.data.token);
          localStorage.setItem("auth_name", res.data.username);
          localStorage.setItem("auth_email", res.data.email);
          localStorage.setItem("auth_lastname", res.data.lastname);
          window.location.reload();
          navigate("/dashboard/add_data");
        } else if (res.data.status === 401) {
          swal("Warning", res.data.message, "warning");
        } else {
          setValues({ ...values, error_list: res.data.validation_errors });
        }
      });
    });
  };

  return (
    <AuthContainer id="hero">
      <AuthBg>
        <VideoBg autoPlay loop muted src={Video} type="video/mp4" />
      </AuthBg>
      <AuthContent>
        <AuthForm onSubmit={handleSubmit}>
          <AuthH1>Login</AuthH1>
          <AuthInput
            type="email"
            name="email"
            placeholder="Email Address"
            onChange={handleChange}
            value={values.email}
          />
          <AuthErrorWrap>
            {values.error_list.email && (
              <AuthError>{values.error_list.email}</AuthError>
            )}
          </AuthErrorWrap>
          <div style={{ position: "relative", left: "8px" }}>
            <AuthInput
              type={type}
              name="password"
              placeholder="Password"
              onChange={handleChange}
              value={values.password}
            />
            <Span onClick={handleToggle}>{eyeIcon}</Span>
          </div>
          <AuthErrorWrap>
            {values.error_list.password && (
              <AuthError>{values.error_list.password}</AuthError>
            )}
          </AuthErrorWrap>
          <AuthForgotPassword to="/forgotPassword">
            forgot password?
          </AuthForgotPassword>
          <AuthButton>Login</AuthButton>
          <Link
            className="link"
            to="/"
            style={{
              marginTop: "15px",
            }}
          >
            Back to Home
          </Link>
        </AuthForm>
      </AuthContent>
    </AuthContainer>
  );
};

export default Login;
