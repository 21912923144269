import React, { useState } from "react";
import {
  AuthBg,
  AuthButton,
  AuthContainer,
  AuthContent,
  AuthError,
  AuthForm,
  AuthH1,
  AuthInput,
  VideoBg,
} from "./AuthElements";
import Video from "../../videos/container2.mp4";
import SignupForm from "./SignupForm";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { admin, adminPWD } from "./AuthContext";

const Signup = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [eyeIcon, setEyeIcon] = useState(FaEyeSlash);
  const [errors, setErrors] = useState("");
  const [type, setType] = useState("password");

  const [values, setValues] = useState({
    admin_id: "",
    password: "",
  });

  const handleToggle = () => {
    if (type === "password") {
      setEyeIcon(FaEye);
      setType("text");
    } else {
      setEyeIcon(FaEyeSlash);
      setType("password");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (values.admin_id === admin && values.password === adminPWD) {
      setIsAdmin(true);
    } else if (values.admin_id === "" || values.password === "") {
      setErrors("Please Enter ID & Password");
    } else {
      setErrors("Invalid Admin ID or Password");
    }
  };

  return (
    <AuthContainer id="hero">
      <AuthBg>
        <VideoBg autoPlay loop muted src={Video} type="video/mp4" />
      </AuthBg>
      <AuthContent>
        {isAdmin ? (
          <SignupForm />
        ) : (
          <AuthForm onSubmit={handleSubmit}>
            <AuthH1>Admin</AuthH1>

            {errors && (
              <AuthError style={{ fontSize: "16px", marginTop: "10px" }}>
                {errors}
              </AuthError>
            )}
            <AuthInput
              type="text"
              id="admin_id"
              name="admin_id"
              placeholder="Admin Id"
              onChange={handleChange}
              value={values.admin_id}
              // required
            />
            <div style={{ position: "relative", left: "8px" }}>
              <AuthInput
                type={type}
                id="password"
                name="password"
                placeholder="Password"
                onChange={handleChange}
                value={values.password}
                // required
              />
              <span
                style={{
                  position: "relative",
                  fontSize: "medium",
                  color: "#000",
                  cursor: "pointer",
                  right: "28px",
                  top: "4px",
                }}
                onClick={handleToggle}
              >
                {eyeIcon}
              </span>
            </div>
            <AuthButton>Login</AuthButton>
          </AuthForm>
        )}
      </AuthContent>
    </AuthContainer>
  );
};

export default Signup;
