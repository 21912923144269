import React, { useState } from "react";
import {
  AuthBg,
  AuthButton,
  AuthContainer,
  AuthContent,
  AuthError,
  AuthErrorWrap,
  AuthForm,
  AuthH1,
  AuthInput,
  ForgotPasswordMsg,
  VideoBg,
} from "./AuthElements";

import Video from "../../videos/container2.mp4";
import { Link } from "react-router-dom";
import axios from "axios";
import "../../App.css";

const ForgotPassword = () => {
  // <------------------------------------ Password Eye Toggle ---------------------------->
  const [values, setValues] = useState({
    email: "",
    password: "",
    error_list: [],
  });

  // <------------------------------------ Forgot Password Function ---------------------------->
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      email: values.email,
    };

    axios.get("/sanctum/csrf-cookie").then((response) => {
      console.log(response);

      axios.post(`api/forgot-password`, data).then((res) => {
        if (res.data.status === 401) {
          alert(res.data.message);
          //   window.location.reload();
        } else if (res.data.status === 200) {
          alert(res.data.message);
        }
      });
    });
  };

  return (
    <AuthContainer id="hero">
      <AuthBg>
        <VideoBg autoPlay loop muted src={Video} type="video/mp4" />
      </AuthBg>
      <AuthContent>
        <AuthForm onSubmit={handleSubmit}>
          <AuthH1>Forgot Password</AuthH1>
          <ForgotPasswordMsg>
            We will send a reset link to your mail, please enter a valid email
            address
          </ForgotPasswordMsg>
          <AuthInput
            type="email"
            name="email"
            placeholder="Email Address"
            onChange={handleChange}
            value={values.email}
          />
          <AuthErrorWrap>
            {values.error_list.email && (
              <AuthError>{values.error_list.email}</AuthError>
            )}
          </AuthErrorWrap>
          <AuthButton>Send Link</AuthButton>
          <Link
            className="link"
            to="/"
            style={{
              marginTop: "15px",
            }}
          >
            Back to Home
          </Link>
        </AuthForm>
      </AuthContent>
    </AuthContainer>
  );
};

export default ForgotPassword;
