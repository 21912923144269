import styled from "styled-components";
import { Link as LinkS } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";

export const SBar = styled.nav`
  background: var(--black);
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 15%;
  color: var(--white);
`;

export const SBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 720px) {
    display: none;
  }
`;

export const SBarLogo = styled.img`
  height: 70px;
  width: 110px;
  margin-top: 10px;

  @media screen and (max-width: 1000px) {
    width: 90px;
    height: 60px;
  }
`;

export const SBarH1 = styled.h1`
  color: var(--white);
  margin-top: 10px;
  font-size: 24px;

  @media screen and (max-width: 1000px) {
    font-size: 18px;
  }
`;

export const SbarMenu = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  text-align: center;
  align-items: center;
`;

export const SBarItems = styled.li`
  margin-top: 10px;
  height: 36px;
`;

export const SBarLinks = styled(LinkS)`
  background: #505050;
  padding: 1.2rem;
  height: 24px;
  width: 10.5rem;
  border-radius: 7px;
  font-size: 14px;
  font-weight: 500;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border: none;
  cursor: pointer;

  &:hover {
    background: var(--white);
    color: var(--black);
  }

  @media screen and (max-width: 1200px) {
    width: 7.5rem;
    height: 20px;
  }

  @media screen and (max-width: 1000px) {
    width: 6.5rem;
    height: 16px;
    font-size: 12px;
    margin: 0 5px;
  }
`;

export const Logout = styled.button`
  padding: 1.2rem;
  height: 18px;
  width: 11rem;
  color: var(--black);
  background: var(--white);
  border: none;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;

  :hover {
    background: var(--dark-gray);
    color: white;
  }

  @media screen and (max-width: 1200px) {
    width: 7.5rem;
    height: 20px;
  }

  @media screen and (max-width: 1000px) {
    width: 6.5rem;
    height: 16px;
  }
`;

export const LogoutIcon = styled(FiLogOut)`
  color: var(--black);
  margin-right: 10px;

  :hover {
    color: white;
  }
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 720px) {
    display: block;
    position: absolute;
    top: 25px;
    left: 20px;
    transform: translated(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }
`;
