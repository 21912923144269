import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  FormInput,
  InputWrapper,
  Label,
  FormLongInput,
  TimeDateWrapper,
  FormContainer,
  FormWrapper,
  FormSection,
  FormSelect,
  FormOption,
  H2,
} from "../AddData/AddDataElements.jsx";

const EditShipping = ({ formData, setFormData }) => {
  // <---------------------------- Fetching Ship Names --------------------------->

  const [shipname, setShipName] = useState([]);
  const getShipName = async () => {
    try {
      const res = await axios.get("/api/view-shipnames");
      setShipName(res.data.shipname);
    } catch {}
  };

  useEffect(() => {
    getShipName();
  }, []);

  // <---------------------------- Fetching Agent Names --------------------------->

  const [agentname, setAgentName] = useState([]);
  const getAgentName = async () => {
    try {
      const res = await axios.get("/api/view-agentnames");
      setAgentName(res.data.agentname);
    } catch {}
  };

  useEffect(() => {
    getAgentName();
  }, []);

  return (
    <FormContainer>
      <H2>Shipping Details</H2>
      <FormWrapper>
        <FormSection>
          <InputWrapper>
            <Label>File No</Label>
            <FormInput
              required
              type="number"
              min={0}
              placeholder="Example 408"
              value={formData.fileNo}
              onChange={(e) =>
                setFormData({ ...formData, fileNo: e.target.value })
              }
            />
          </InputWrapper>
          <InputWrapper>
            <Label>Name of Vessel</Label>
            <FormSelect
              required
              value={formData.vesselName}
              onChange={(e) =>
                setFormData({ ...formData, vesselName: e.target.value })
              }
            >
              <FormOption value="">select ship name</FormOption>
              {shipname.map((item) => {
                return <FormOption key={item.id}>{item.shipname}</FormOption>;
              })}
            </FormSelect>
          </InputWrapper>
          <InputWrapper>
            <Label>Anchorage Time & Date</Label>
            <TimeDateWrapper>
              <FormLongInput
                type="time"
                required
                value={formData.anchorageTime}
                onChange={(e) =>
                  setFormData({ ...formData, anchorageTime: e.target.value })
                }
              />
              <FormLongInput
                style={{ width: "7.8rem" }}
                type="date"
                required
                value={formData.anchorageDate}
                onChange={(e) =>
                  setFormData({ ...formData, anchorageDate: e.target.value })
                }
              />
            </TimeDateWrapper>
          </InputWrapper>
          <InputWrapper>
            <Label> Departure Time & Date</Label>
            <TimeDateWrapper>
              <FormLongInput
                type="time"
                required
                value={formData.departureTime}
                onChange={(e) =>
                  setFormData({ ...formData, departureTime: e.target.value })
                }
              />
              <FormLongInput
                type="date"
                required
                style={{ width: "7.8rem" }}
                value={formData.departureDate}
                onChange={(e) =>
                  setFormData({ ...formData, departureDate: e.target.value })
                }
              />
            </TimeDateWrapper>
          </InputWrapper>
          <InputWrapper>
            <Label>Purpose</Label>
            <FormInput
              required
              type="text"
              value={formData.purpose}
              onChange={(e) =>
                setFormData({ ...formData, purpose: e.target.value })
              }
            />
          </InputWrapper>
          <InputWrapper>
            <Label>Berthing Position</Label>
            <FormInput
              type="text"
              required
              value={formData.berthingPosition}
              onChange={(e) =>
                setFormData({ ...formData, berthingPosition: e.target.value })
              }
            />
          </InputWrapper>
          <InputWrapper>
            <Label>C.M.B Agents</Label>
            <FormSelect
              required
              value={formData.cmbAgents}
              onChange={(e) =>
                setFormData({ ...formData, cmbAgents: e.target.value })
              }
            >
              <FormOption value="">select agent name</FormOption>
              {agentname.map((item) => {
                return <FormOption>{item.agentname}</FormOption>;
              })}
            </FormSelect>
          </InputWrapper>
        </FormSection>
        <FormSection>
          <InputWrapper>
            <Label>S.L.P.A Ref No</Label>
            <FormInput
              type="text"
              required
              value={formData.slpaRefNo}
              onChange={(e) =>
                setFormData({ ...formData, slpaRefNo: e.target.value })
              }
            />
          </InputWrapper>
          <InputWrapper>
            <Label>Flag</Label>
            <FormInput
              required
              type="text"
              value={formData.flag}
              onChange={(e) =>
                setFormData({ ...formData, flag: e.target.value })
              }
            />
          </InputWrapper>
          <InputWrapper>
            <Label>L.O.A</Label>
            <FormInput
              required
              type="text"
              value={formData.loa}
              onChange={(e) =>
                setFormData({ ...formData, loa: e.target.value })
              }
            />
          </InputWrapper>
          <InputWrapper>
            <Label>G.R.T</Label>
            <FormInput
              type="text"
              required
              value={formData.grt}
              onChange={(e) =>
                setFormData({ ...formData, grt: e.target.value })
              }
            />
          </InputWrapper>
          <InputWrapper>
            <Label>N.R.T</Label>
            <FormInput
              type="text"
              required
              value={formData.nrt}
              onChange={(e) =>
                setFormData({ ...formData, nrt: e.target.value })
              }
            />
          </InputWrapper>
          <InputWrapper>
            <Label>IMO No</Label>
            <FormInput
              type="text"
              required
              value={formData.imoNo}
              onChange={(e) =>
                setFormData({ ...formData, imoNo: e.target.value })
              }
            />
          </InputWrapper>
          <InputWrapper>
            <Label>Call Sign</Label>
            <FormInput
              type="text"
              required
              value={formData.callSign}
              onChange={(e) =>
                setFormData({ ...formData, callSign: e.target.value })
              }
            />
          </InputWrapper>
        </FormSection>
      </FormWrapper>
    </FormContainer>
  );
};

export default EditShipping;
