import axios from "axios";
import React, { useState } from "react";
import { currentUser } from "../../Auth/AuthContext.js";
import {
  AddDataForm,
  DetailWrapper,
  FormButton,
  FormFooter,
} from "./AddDataElements.jsx";
import InvoiceDetails from "./InvoiceDetails.jsx";
import ShippingDetails from "./ShippingDetails.jsx";

const AddData = () => {
  const [formData, setFormData] = useState({
    fileNo: "",
    vesselName: "",
    anchorageTime: "",
    anchorageDate: "",
    departureTime: "",
    departureDate: "",
    purpose: "",
    berthingPosition: "",
    cmbAgents: "",
    slpaRefNo: "",
    flag: "",
    loa: "",
    grt: "",
    nrt: "",
    imoNo: "",
    callSign: "",
    invoiceNo: "",
    invoiceDate: "",
    invoiceDesc: "",
    invoiceAmountSLR: "",
    invoiceTotal: "",
    invoiceAmountUSD: "",
    exchangeRate: "",
    paymentRecieved: "",
    paymentDate: "",
    chequeNoOnline: "",
    balance: "",
    remark: "",
    enteredBy: currentUser,
    error_list: [],
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post("/api/store-details", formData).then((res) => {
      if (res.data.status === 200) {
        setFormData({
          ...formData,
          fileNo: "",
          vesselName: "",
          anchorageTime: "",
          anchorageDate: "",
          departureTime: "",
          departureDate: "",
          purpose: "",
          berthingPosition: "",
          cmbAgents: "",
          slpaRefNo: "",
          flag: "",
          loa: "",
          grt: "",
          nrt: "",
          imoNo: "",
          callSign: "",
          invoiceNo: "",
          invoiceDate: "",
          invoiceDesc: "",
          invoiceAmountSLR: "",
          invoiceTotal: "",
          invoiceAmountUSD: "",
          exchangeRate: "",
          paymentRecieved: "",
          paymentDate: "",
          chequeNoOnline: "",
          balance: "",
          remark: "",
          // enteredBy: "",
          error_list: "",
        });
        alert(res.data.message);
      } else if (res.data.status === 400) {
        setFormData({ ...formData, error_list: res.data.errors });
      }
    });
  };

  return (
    <AddDataForm onSubmit={handleSubmit}>
      <DetailWrapper>
        <ShippingDetails formData={formData} setFormData={setFormData} />
        <InvoiceDetails formData={formData} setFormData={setFormData} />
      </DetailWrapper>
      <FormFooter>
        <FormButton type="submit">Submit</FormButton>
      </FormFooter>
    </AddDataForm>
  );
};

export default AddData;
