import { Link } from "react-router-dom";
import styled from "styled-components";

export const AuthContainer = styled.div`
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 100vh;
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.1) 100%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, transparent 1%);
    z-index: 2;
  }
`;

export const AuthBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
`;

export const VideoBg = styled.video`
  width: 100%;
  height: 100vh;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
`;

export const AuthContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  position: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AuthH1 = styled.h1`
  color: #fff;
  font-size: 32px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const AuthP = styled.p`
  margin-top: 4px;
  color: #fff;
  font-size: 24px;
  text-align: center;
  max-width: 600px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

/* ------------------------------ Authentication --------------------------- */

export const AuthForm = styled.form`
  /* margin-top: 180px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 10px;
  background: #00000086;
`;

export const AuthInput = styled.input`
  color: black;
  border: none;
  border-radius: 7px;
  background: #ffffff;
  padding-left: 12px;
  width: 17rem;
  height: 2.3rem;
  margin-top: 12px;

  &:focus {
    outline: 2px solid var(--dark);
  }
`;

export const AuthButton = styled.button`
  width: 17rem;
  height: 2.3rem;
  border: none;
  border-radius: 7px;
  background: black;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  margin-top: 8px;

  &:focus {
    background: var(--dark-gray);
  }
`;

export const AuthErrorWrap = styled.div`
  /* background: #b3b3b37a; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 7px;
  margin-top: 5px;
  width: 17rem;
`;

export const AuthError = styled.p`
  color: #ff7171;
  font-size: 12px;
  font-weight: 800;
`;

export const AuthForgotPassword = styled(Link)`
  text-decoration: none;
  font-size: 12px;
  color: white;
  margin-left: 10rem;
  margin-top: 5px;
  margin-bottom: 5px;
  :hover {
    color: blue;
  }
`;

export const ForgotPasswordMsg = styled.div`
  margin: 15px 0 10px 0;
  width: 17rem;
  font-size: 14px;
  text-align: center;
  padding: 10px;
  background-color: #ca892788;
  border-radius: 10px;
  color: white;
`;

export const Span = styled.span`
  position: relative;
  font-size: medium;
  color: #000;
  cursor: pointer;
  right: 28px;
  top: 4px;
`;
